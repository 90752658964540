<template>
  <header
    id="header-nav"
    ref="header"
    data-scroll
    data-scroll-sticky
    data-scroll-speed="1"
    data-scroll-target="#root-container"
    class="no-cursor header-nav white"
    :class="`${navOpen ? 'nav-open' : ''} ${
      device === 'tablet' || device === 'mobile' ? 'mobile' : '' }
      ${sticky ? 'sticky-header' : ''}`">
    <nav ref="navWrapper">
      <div class="branding">
        <div class="logo-container">
          <a
            href="/"
            @click="close(false)">
            <transition
              name="fade"
              mode="out-in">
              <template v-if="device === 'desktop'">
                <DotLottieVue
                  ref="headerAnimation"
                  class="site-logo"
                  :autoplay="false"
                  :src="navAnimation" />
                <!-- <img
                  v-if="site.site_logo?.url"
                  :src="site.site_logo?.url || ''"
                  alt="site-logo" /> -->
              </template>
              <template v-else>
                <img
                  :src="site.site_logo_sticky?.url || ''"
                  alt="site-logo" />
              </template>
            </transition>
          </a>
        </div>
        <div
          class="mobile-nav"
          @click="toggle">
          <span class="line line-one" />
          <span class="line line-two" />
          <span class="line line-three" />
        </div>
      </div>
      <div class="nav-container-wrapper">
        <ul
          ref="nav"
          class="nav-container">
          <template v-if="introShown">
            <transition-group
              v-for="(item, index) in menu.menuItems.nodes"
              :key="item.id"
              tag="li"
              appear
              class="nav-container-items"
              name="fade"
              @click="item.cssClasses[0] === '' ? close(false) : null">
              <NavMenuItem
                :key="item.id"
                :data-ind="index"
                :item="item"
                :mobile="device === 'mobile' ? 'mobile-menu-show' : ''"
                @click="toggle" />
            </transition-group>
          </template>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script setup>
import { DotLottieVue } from '@lottiefiles/dotlottie-vue'
import navAnimation from '@/assets/lottie/logo_animated.json?url'
import NavMenuItem from './NavMenuItem.vue'
import { ref, watch, onMounted } from 'vue'
import { useStore } from '@/stores/main'
import useUtils from '@/composables/useUtils.js'
import useIntroShown from '@/composables/useIntroShown.js'
import useSmoothScroll from '@/composables/useSmoothScroll.js'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const store = useStore()
const menu = ref(store.menu({ name: 'main-menu' }))
const site = ref(store.acfOptions())
const header = ref()
const sticky = ref()
const navOpen = ref()
const transitioning = ref(false)
const scrollPosition = ref(0)
const { device } = useUtils()
const { introShown } = useIntroShown()
const { smoothScroll } = useSmoothScroll()
const headerAnimation = ref()

watch(scrollPosition, (newVal) => {
  scrollPosition.value = newVal
  if (newVal > 20) {
    sticky.value = true
  } else {
    sticky.value = false
  }
})

watch(introShown, (newVal) => {
  if (newVal) {
    const dotLottie = headerAnimation.value.getDotLottieInstance()
    dotLottie.play()
    dotLottie.addEventListener('frame', ({ currentFrame }) => {
      if (currentFrame > 60) {
        dotLottie.pause()
      }
    })
  }
})

const playAnimation = () => {
  if (!headerAnimation.value) {
    setTimeout(() => {
      playAnimation()
    }, 100)
  } else {
    const dotLottie = headerAnimation.value.getDotLottieInstance()
    dotLottie.play()
    dotLottie.addEventListener('frame', ({ currentFrame }) => {
      if (currentFrame > 60) {
        dotLottie.pause()
      }
    })
  }
}

watch(device, () => {
  playAnimation()
})

onMounted(() => {
  if (introShown.value) {
    if (device.value !== 'desktop') return
    const dotLottie = headerAnimation.value.getDotLottieInstance()
    dotLottie.addEventListener('frame', ({ currentFrame }) => {
      if (currentFrame < 60) {
        dotLottie.play()
      } else {
        dotLottie.pause()
      }
    })
  }
  show_hide_header()
  smoothScroll.on('scroll', (e) => {
    handleScroll(e)
  })
})

const handleScroll = (e) => {
  scrollPosition.value = e.animatedScroll // Adjust the threshold as needed
}

const toggle = () => {
  navOpen.value = !navOpen.value
}
const close = (data) => {
  if (data !== undefined) navOpen.value = data
  else navOpen.value = !navOpen.value
}

const show_hide_header = () => {
  if (!header.value) {
    setTimeout(() => {
      show_hide_header()
    }, 100)
  } else {
    const site_header = header.value

    const show_hide_header = gsap
      .from(site_header, {
        yPercent: -100,
        duration: 0.3,
        ease: 'sine.out',
      })
      .progress(1)

    ScrollTrigger.create({
      start: 'top top-=' + 100,
      endTrigger: 'body',
      end: 'bottom top',
      markers: false,
      onUpdate: (self) => {
        if (self.direction === -1) show_hide_header.play()
        else show_hide_header.reverse()
      },
    })
  }
}

watch(
  () => [navOpen.value, device.value],
  ([newVal, newDevice]) => {
    if (!document.querySelector('.nav-container-items')) return
    transitioning.value = true
    if (newVal && newDevice !== 'desktop') {
      smoothScroll.stop()
      gsap.to('.nav-container-items', {
        duration: 1,
        opacity: 1,
        stagger: 0.1,
        delay: 0.3,
        onComplete: () => {
          transitioning.value = false
        },
      })
    } else if (newDevice === 'desktop') {
      smoothScroll.start()
      gsap.to('.nav-container-items', {
        duration: 1,
        opacity: 1,
        stagger: 0.1,
        delay: 1,
        onComplete: () => {
          transitioning.value = false
        },
      })
    } else if (!newVal && newDevice !== 'desktop') {
      smoothScroll.start()
      gsap.to('.nav-container-items', {
        duration: 0.5,
        opacity: 0,
        stagger: 0.1,
        onComplete: () => {
          transitioning.value = false
        },
      })
    }
  }
)
</script>

<style lang="scss">
#header-nav.mobile {
  @apply fixed left-0 right-0 top-0;
  nav {
    @apply relative bg-white;
  }
  .nav-container-wrapper {
    @apply pointer-events-none absolute left-0 top-full -z-1 h-screen w-screen origin-top bg-bicara-turquoise bg-menu-background opacity-0 transition-all duration-700 ease-in-out;
    @apply bg-cover bg-center bg-no-repeat;
    .nav-container {
      @apply mx-auto w-[96%] pt-24;
      .nav-container-items {
        @apply bg-transparent opacity-0;
      }
    }
  }
}

#header-nav {
  @apply fixed left-0 right-0 top-0 shadow-none transition-all duration-300 ease-in-out lg:px-3;

  .logo-container img {
    @apply w-48 transition-all lg:w-full;
  }
  .nav-container-wrapper {
    @apply w-full transition-all lg:h-auto;
  }
}

#header-nav.sticky-header {
  @apply bg-white lg:shadow-md;
}

#header-nav.nav-open {
  @apply max-h-[100vh] lg:max-h-[110px];

  .line-one {
    @apply translate-x-[0px] translate-y-4 rotate-45 transform;
  }
  .line-three {
    @apply -translate-x-[0px] -translate-y-[4px] -rotate-45 transform;
  }
  .line-two {
    @apply opacity-0;
  }

  .nav-container-wrapper {
    @apply pointer-events-auto top-0 opacity-100;
  }
}
.header-nav {
  @apply top-0 z-10 mb-0 mt-0 flex w-screen max-w-full items-stretch justify-center bg-transparent lg:overflow-visible lg:pb-2 lg:pt-6;

  nav {
    @apply container flex flex-col items-stretch pt-0 transition-all duration-300 ease-in-out xl:inner-container lg:flex-row lg:items-center lg:justify-between lg:px-0 lg:py-0;

    .nav-container {
      @apply my-0 flex flex-col items-center justify-start px-xl lg:flex-row lg:justify-end lg:gap-6 lg:px-0 xl:gap-10 xl:px-0;
      li {
        @apply w-full border border-b-0 border-l-0 border-r-0 border-t-[1px] border-bicara-blue text-center lg:w-auto lg:border-t-0;

        &:first-child {
          @apply border-t-0;
        }
      }
    }
  }
  .branding {
    @apply flex w-full items-center justify-between p-3 text-center shadow-none md:text-left lg:max-h-[60px] lg:w-[242px] lg:flex-[1_0_auto] lg:p-0 xl:p-0;
    .logo-container {
      @apply flex h-full items-center lg:inline-block xl:max-h-full;
      img {
        @apply h-full max-h-[60px] w-[90%] lg:w-auto;
      }
    }
    .mobile-nav {
      @apply float-right flex flex-col p-3 lg:hidden;
      .line {
        @apply mb-2 transition-all duration-[0.5s] ease-in-out;
      }
      .line:before {
        @apply block h-[2px] w-[30px] bg-bicara-dark-blue content-[''];
      }
    }
  }

  .nav-container {
    @apply m-0 flex h-full w-full items-start justify-start text-left transition duration-500 ease-in-out;
    .nav-container-items {
      @apply flex w-full items-center text-center lg:w-auto;
      div.menu-items {
        @apply header-2 flex h-full items-start justify-start text-left lg:body-sm;
      }
    }
  }
}
</style>
