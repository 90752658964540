import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import locoScroll from '../assets/js/LenisScroll'
import emitter from '@/scripts/emitter'

const { site } = window.__VUE_WORDPRESS__.state

export const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      emitter.once('pageTransition', () => {
        if (to.hash) {
          locoScroll.scrollTo(to.hash, {
            immediate: true,
            onComplete: () => {
              emitter.emit('scrolled')
              resolve()
            },
          })
          emitter.emit('scrolled')
          resolve()
        } else {
          if (savedPosition) {
            const scrollValue =
              savedPosition.top === 0 ? 'top' : savedPosition.top
            locoScroll.scrollTo(scrollValue, {
              immediate: true,
              onComplete: () => {
                emitter.emit('scrolled')
                resolve()
              },
            })
            emitter.emit('scrolled')
            resolve()
          } else {
            locoScroll.scrollTo('top', {
              immediate: false,
              duration: 1,
              onComplete: () => {},
            })
            emitter.emit('scrolled')
            resolve()
          }
        }
      })
    })
  },
})

const initialScroll = (to) => {
  // check if site intro has completed
  const introComplete = sessionStorage.getItem('introShown')
  if (!introComplete) {
    setTimeout(() => {
      initialScroll(to)
    }, 100)
  } else {
    setTimeout(() => {
      locoScroll.scrollTo(to.hash, {
        immediate: false,
        duration: 1,
        onComplete: () => {
          console.log('completed scroll to: ', to.hash)
        },
      })
    }, 500)
  }
}

router.afterEach((to) => {
  // initial page load scroll to hash
  window.requestIdleCallback(() => {
    if (to.hash) {
      initialScroll(to)
    }
  })
  // set page title on route change
  window.document.title =
    `${to.meta.title} | ${site.name}` || `${site.name} | ${site.description}`
})
