<template>
  <div class="footer-nav-item-container">
    <nav>
      <ul
        v-if="menu"
        class="footer-nav-container">
        <template
          v-for="item in menu.menuItems.nodes"
          :key="item.id">
          <li :class="item.cssClasses.toString()">
            <template v-if="isExternal(item.uri)">
              <a
                :href="item.uri"
                target="_blank"
                :title="item.label"
                v-html="item.label"></a>
            </template>
            <template v-else>
              <quick-link
                :to="item.uri"
                :title="item.label">
                {{ item.label }}
              </quick-link>
            </template>
          </li>
        </template>
      </ul>
    </nav>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from '@/stores/main'
import useUtils from '@/composables/useUtils.js'

const store = useStore()
const props = defineProps(['name'])
const { isExternal } = useUtils()
const menu = ref(store.menu({ name: props.name }))
</script>

<style lang="scss">
.footer-nav-container {
  @apply flex flex-col;

  li {
    @apply mb-7;

    a {
      @apply cta-2;
    }
  }
}
</style>
