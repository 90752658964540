<template>
  <div
    class="menu-items group relative items-center"
    :class="`${hasSub(item)} ${device === 'mobile' ? 'mobile-subnav-root' : ''}`">
    <template v-if="item.cssClasses.includes('has-subnav')">
      <template v-if="item.uri !== '#'">
        <template v-if="device === 'mobile'">
          <div class="submenu-text-container">
            <quick-link
              class="mobile-subnav-parent lg:mb-0"
              :to="convertToRelative(item.uri)"
              >{{ item.label }}</quick-link
            >
          </div>
          <div
            class="submenu-trigger"
            @click="openSubnav">
            <img
              class="caret"
              :class="subnavOpen ? 'rotate-180 transform' : ''"
              src="@/assets/img/caret-down.svg"
              alt="dropdown-caret" />
          </div>
        </template>
        <template v-else>
          <quick-link
            class="desktop-subnav-parent mb-3 lg:mb-0"
            :to="convertToRelative(item.uri)">
            {{ item.label }}
            <img
              class="caret ml-2"
              :class="sticky ? 'sticky' : ''"
              src="@/assets/img/caret-down.svg"
              alt="dropdown-caret" />
          </quick-link>
        </template>
      </template>
      <template v-else>
        <div class="desktop-subnav-parent mb-0 lg:mb-3">
          {{ item.label }}
          <div
            class="chevron ml-2 flex transform items-center transition-transform ease-in-out
              group-hover:rotate-180"
            :class="{ 'rotate-180': subnavOpen }">
            <svg
              width="13px"
              height="6px"
              viewBox="0 0 13 6"
              version="1.1"
              stroke="inherit">
              <g
                stroke="inherit"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
                stroke-dasharray="0 0">
                <g
                  transform="translate(-1074.000000, -93.000000)"
                  stroke="inherit">
                  <g transform="translate(711.000000, 86.000000)">
                    <g transform="translate(304.000000, 0.000000)">
                      <path d="M60,7 L65.5,13 L71,7" />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </template>
      <template v-if="device !== 'desktop'">
        <Vue3SlideUpDown
          v-model="subnavOpen"
          class="subnav-accordion">
          <ul
            v-if="item.childItems.nodes.length > 0"
            class="sub-menu">
            <template
              v-for="sub in item.childItems.nodes"
              :key="sub.id">
              <li :title="sub.label">
                <template v-if="isExternal(sub.uri)">
                  <a
                    :href="sub.uri"
                    :target="sub.target"
                    :title="sub.label"
                    @click="closeNav"
                    >{{ sub.label }}</a
                  >
                </template>
                <template v-else>
                  <quick-link
                    :to="convertToRelative(sub.uri)"
                    @click="closeNav"
                    >{{ sub.label }}</quick-link
                  >
                </template>
              </li>
            </template>
          </ul>
        </Vue3SlideUpDown>
      </template>
      <template v-else>
        <ul
          v-if="item.childItems.nodes.length > 0"
          class="sub-menu">
          <template
            v-for="sub in item.childItems.nodes"
            :key="sub.id">
            <li
              :title="sub.label"
              :class="arrayToString(sub.cssClasses)">
              <template v-if="isExternal(sub.uri)">
                <a
                  :href="sub.uri"
                  :class="arrayToString(sub.cssClasses)"
                  :target="sub.target"
                  :title="sub.label"
                  >{{ sub.label }}</a
                >
              </template>
              <template v-else>
                <quick-link
                  :to="convertToRelative(sub.uri)"
                  @click="closeNav"
                  >{{ sub.label }}</quick-link
                >
              </template>
            </li>
          </template>
          <div class="triangle"></div>
        </ul>
      </template>
    </template>
    <template
      v-else-if="item.cssClasses.includes('CTA') && !device === 'mobile'">
      <quick-link
        :to="convertToRelative(item.uri)"
        exact
        @click="closeNav">
        {{ item.label }}
        <svg
          class="ml-2"
          width="17"
          height="8"
          viewBox="0 0 17 8"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.844 4.18558C17.0393 3.99032 17.0393 3.67374 16.844 3.47848L13.6621 0.296497C13.4668 0.101235 13.1502 0.101235 12.955 0.296497C12.7597 0.491759 12.7597 0.808342 12.955 1.0036L15.7834 3.83203L12.955 6.66046C12.7597 6.85572 12.7597 7.1723 12.955 7.36757C13.1502 7.56283 13.4668 7.56283 13.6621 7.36757L16.844 4.18558ZM0.925781 4.33203H16.4905V3.33203H0.925781V4.33203Z" />
        </svg>
      </quick-link>
    </template>
    <template v-else-if="isExternal(item.uri)">
      <a
        :href="item.uri"
        :target="item.target"
        :title="item.label"
        v-html="item.label"></a>
    </template>
    <template v-else>
      <quick-link
        :to="convertToRelative(item.uri)"
        exact
        @click="closeNav"
        >{{ item.label }}</quick-link
      >
    </template>
  </div>
</template>

<script setup>
import { Vue3SlideUpDown } from 'vue3-slide-up-down'
import useUtils from '@/composables/useUtils.js'
import { ref, watch } from 'vue'

const { device, scrollY, convertToRelative, isExternal } = useUtils()
defineProps(['item'])
const subnavOpen = ref()
const sticky = ref()

watch(scrollY, (newVal) => {
  if (newVal > 50) {
    sticky.value = true
  } else {
    sticky.value = false
  }
})

const hasSub = (item) => {
  return item.cssClasses.includes('has-subnav')
    ? `parent-nav ${arrayToString(item.cssClasses)}`
    : `${arrayToString(item.cssClasses)}`
}
const openSubnav = () => {
  subnavOpen.value = !subnavOpen.value
}

const closeNav = () => {
  subnavOpen.value = false
}

const arrayToString = (array) => {
  return array.join(' ')
}
</script>

<style lang="scss">
.caret {
  @apply filter transition duration-500 lg:brightness-100;
}

.menu-items {
  @apply mx-0 w-full px-0;

  &.has-subnav {
    @apply flex;
    .mobile-subnav-parent {
      @apply w-max;
    }
    .submenu-text-container {
      @apply block py-2;
    }
    .submenu-trigger {
      @apply absolute -right-0 top-2 z-1 h-[47px] w-full lg:-right-10 lg:top-0;
      .caret {
        @apply absolute right-[25px] top-[22px] ml-2;
      }
    }
  }
  @apply relative flex flex-col fill-white stroke-white py-3 md:pb-5 lg:py-0;

  a {
    @apply relative w-full text-bicara-dark-blue opacity-100 transition duration-200 ease-in-out hover:opacity-100;

    &:hover {
      @apply text-bicara-blue;
    }

    &.router-link-active {
      @apply font-normal text-bicara-blue;

      &:before {
        @apply absolute -left-8 top-[calc(50%_-_12px)] h-5 w-5 rounded-full bg-bicara-yellow bg-no-repeat content-[''] lg:-bottom-4 lg:left-[calc(50%_-_10px)] lg:top-auto lg:bg-transparent lg:bg-[url('@/assets/img/menu_active.svg')] lg:bg-contain lg:bg-center;
      }
    }
  }
  a.quick-link-active {
    @apply opacity-70;
  }
  .subnav-accordion {
    @apply w-full;
  }
}

.desktop-subnav-parent {
  @apply flex flex-row justify-center opacity-60 transition-opacity duration-200 hover:opacity-100;
  stroke: inherit;
  .chevron {
    stroke: inherit;
    svg {
      stroke: inherit;
    }
  }
}

.mobile-subnav-parent {
  @apply inline-block opacity-60 transition-opacity duration-200 hover:opacity-100;
  stroke: inherit;
}

.mobile-subnav-root {
  img {
    &::before {
      @apply absolute h-full w-full bg-green-500 content-[''];
    }
  }
}

li.CTA {
  @apply lg:p-0;
  a {
    @apply lg:flex lg:content-center lg:items-center lg:p-3 lg:px-6;
    svg {
      fill: inherit;
      stroke: none;
    }
  }
}
.sub-menu {
  @apply relative flex w-full flex-col transition-all md:shadow-xl lg:absolute lg:right-[-15px] lg:top-0 lg:translate-y-[-999999px] lg:transform lg:bg-white xl:w-max xl:max-w-[800px];
  li {
    padding: 0 !important;
    @apply relative flex w-full border-b-[1px] border-white/10 py-4 text-center text-black lg:w-auto lg:border-b lg:border-black lg:border-opacity-10 lg:text-left xl:justify-start;
    &:last-child {
      @apply border-none pb-0;
    }
    a {
      @apply flex w-full justify-center text-sm lg:p-10 lg:pb-0 lg:pr-16 lg:text-black lg:last:py-3;
    }
  }
  &:hover {
    li {
      a {
        @apply text-gray-800;
        &:hover {
          @apply bg-bicara-blue text-white;
        }
      }
    }
  }
}
@media all and (min-width: 1024px) {
  ul.sub-menu .triangle {
    content: '';
    position: absolute;
    top: -10px;
    right: 12px;
    height: 10px;
    width: 10px;
    background-color: #ffffff00;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #e9edee;
    clear: both;
    transform: rotate(180deg);
  }
  .sub-menu > li:nth-child(1):hover ~ .triangle {
    border-top: 10px solid rgba(0, 181, 223, 1);
  }
}

.parent-nav:hover .sub-menu {
  @apply min-w-max transition-opacity duration-[0.5s] ease-in-out lg:translate-y-[86px] lg:transform lg:opacity-100;
}
</style>
