<template>
  <transition>
    <footer
      v-if="acf_options"
      id="site-footer"
      class="site-footer">
      <img
        ref="footerLogoRef"
        src="@/assets/img/logo_icon.svg"
        class="background-image"
        alt="Logo" />
      <div class="gutter-wrapper">
        <div class="footer-container">
          <div class="footer-head">
            <router-link
              :to="'/'"
              class="footer-logo"
              title="Home">
              <img
                v-if="acf_options.site_logo_footer"
                class="h-auto w-60"
                :src="acf_options.site_logo_footer.url"
                :alt="acf_options.site_logo_footer.alt" />
            </router-link>
            <div class="social-media">
              <div class="menus-container">
                <div class="footer-col-1">
                  <FooterMenu name="footer-menu-column-1" />
                </div>
                <div class="footer-col-2">
                  <FooterMenu name="footer-menu-column-2" />
                </div>
              </div>

              <div class="social-container">
                <a
                  v-if="acf_options.x"
                  :href="acf_options.x"
                  target="_blank">
                  <svg
                    width="37"
                    height="38"
                    viewBox="0 0 37 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M18.3699 16.5404L11.0427 5.54004H7.13379L16.2136 19.1711L17.3543 20.8832L25.1267 32.5517H29.0357L19.5106 18.2524L18.3699 16.5404Z"
                      fill="#F4F3ED" />
                    <path
                      d="M33.5378 0H2.67165C1.19372 0 0 1.23286 0 2.74411V35.2559C0 36.7671 1.19372 38 2.67165 38H33.5188C34.9968 38 36.1905 36.7671 36.1905 35.2559V2.74411C36.2094 1.23286 35.0157 0 33.5378 0ZM23.9293 34.4704L16.064 22.458L6.2168 34.4704H3.6721L14.9347 20.732L3.67021 3.52957H12.2612L19.7096 14.9057L29.0339 3.52957H31.5786L20.8389 16.6297L32.5184 34.4684H23.9274L23.9293 34.4704Z"
                      fill="#F4F3ED" />
                  </svg>
                </a>

                <a
                  v-if="acf_options.linkedin"
                  :href="acf_options.linkedin"
                  target="_blank">
                  <svg
                    width="39"
                    height="38"
                    viewBox="0 0 39 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <mask
                      id="mask0_2019_2330"
                      style="mask-type: alpha"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="39"
                      height="38">
                      <rect
                        x="0.19043"
                        width="38"
                        height="38"
                        fill="inherit" />
                    </mask>
                    <g mask="url(#mask0_2019_2330)"></g>
                    <g clip-path="url(#clip0_2019_2330)">
                      <path
                        d="M38.1904 2.80778V35.1922C38.1904 36.7333 36.9449 38 35.3827 38H2.99821C1.4571 38 0.19043 36.7544 0.19043 35.1922V2.80778C0.19043 1.24556 1.43599 0 2.99821 0H35.3827C36.9238 0 38.1904 1.24556 38.1904 2.80778ZM11.506 14.2289H5.78487V32.3844H11.506V14.2289ZM11.9493 8.42333C11.9493 6.52333 10.4715 5.15111 8.65599 5.15111C6.84043 5.15111 5.36265 6.54444 5.36265 8.42333C5.36265 10.3022 6.84043 11.6956 8.65599 11.6956C10.4715 11.6956 11.9493 10.3022 11.9493 8.42333ZM32.5749 21.1533C32.5749 15.6011 29.1338 13.7222 25.946 13.7222C22.9904 13.7222 21.0693 15.6222 20.5204 16.7411H20.4571V14.2289H14.9682V32.3844H20.6893V22.5467C20.6893 19.9289 22.3571 18.6411 24.046 18.6411C25.6504 18.6411 26.896 19.5489 26.896 22.4622V32.3844H32.6171V21.1533H32.5749Z"
                        fill="#F4F3ED" />
                    </g>
                    <defs>
                      <clipPath id="clip0_2019_2330">
                        <rect
                          width="38"
                          height="38"
                          fill="inherit"
                          transform="translate(0.19043)" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div class="footer-body">
            <p class="rights">
              Copyright © {{ currentYear }} Bicara Therapeutics
              <span class="opacity-0 lg:opacity-100"> | </span>
              <br class="lg:hidden" />

              <template
                v-for="(link, indx) of acf_options.footer_links"
                :key="`footer-link-${indx}`">
                <a
                  :href="link.link.url"
                  :target="link.link.target"
                  class="mr-1"
                  >{{ link.link.title }}</a
                >
                {{ indx < acf_options.footer_links.length - 1 ? ' | ' : '' }}
              </template>
            </p>
          </div>
        </div>
      </div>
    </footer>
  </transition>
</template>

<script setup>
import FooterMenu from '@/components/template-parts/FooterMenu.vue'
import { useStore } from '@/stores/main'
import {
  ref,
  computed,
  onBeforeMount,
  onMounted,
  watch,
  onBeforeUnmount,
} from 'vue'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import useSiteLoading from '@/composables/useSiteLoading'

const { siteLoading } = useSiteLoading()

gsap.registerPlugin(ScrollTrigger)

const store = useStore()
const acf_options = ref(null)
const footerLogoRef = ref(null)
const currentYear = computed(() => {
  return new Date().getFullYear()
})

const lineAnimation = () => {
  if (!footerLogoRef.value) {
    setTimeout(() => {
      lineAnimation()
    }, 100)
  } else {
    gsap.fromTo(
      footerLogoRef.value,
      { y: '50%' },
      {
        y: '0',
        scrollTrigger: {
          trigger: '#site-footer',
          id: 'footerLogo',
          start: 'top-=100% center',
          end: 'top+=8% center',
          scrub: true,
          markers: false,
        },
      }
    )
  }
}

watch(siteLoading, () => {
  setTimeout(() => {
    ScrollTrigger.refresh()
  }, 500)
})

onBeforeMount(async () => {
  acf_options.value = await store.acfOptions()
})

onBeforeUnmount(() => {
  ScrollTrigger.getById('footerLogo')?.kill()
})

onMounted(() => {
  lineAnimation()
})
</script>

<style lang="scss">
.site-footer {
  @apply relative mx-auto w-full overflow-hidden bg-bicara-dark-blue pb-7 pt-16 text-white lg:pb-16 lg:pt-36;
}

.background-image {
  @apply pointer-events-none absolute left-0 top-0 h-full w-full max-w-none bg-cover object-cover lg:h-auto lg:w-[55%];
}

.gutter-wrapper {
  @apply inner-container;

  a {
    &:hover {
      @apply text-bicara-yellow transition-colors;

      svg {
        path {
          @apply fill-bicara-yellow transition-all;
        }
      }
    }
  }
}

.footer-container {
  @apply divide-text;
}

.footer-head {
  @apply flex flex-col px-xl lg:flex-row lg:justify-between lg:px-5 lg:pb-8 lg:pt-10;

  .footer-logo {
    @apply h-fit;
  }

  .social-media {
    @apply flex flex-col items-start py-4 lg:flex-row lg:gap-16 lg:py-0 xl:gap-32;

    .menus-container {
      @apply my-20 flex flex-col lg:my-0 lg:flex-row lg:items-start lg:gap-24;
    }

    .social-container {
      @apply relative flex w-full flex-row gap-6 py-6 lg:py-0;

      &:before {
        @apply absolute -left-8 -right-8 top-0 h-[1px] bg-white content-[''] lg:hidden;
      }

      &:after {
        @apply absolute -left-8 -right-8 bottom-0 h-[1px] bg-white content-[''] lg:hidden;
      }

      a {
        @apply mr-4;
      }
    }
  }
}

.footer-body {
  @apply flex flex-col px-xl pb-0 pt-4 text-[11px] text-white lg:flex-row lg:justify-between lg:px-5;

  .rights {
    @apply text-left text-[16px] text-white;
  }
}
</style>
