import useUtils from './useUtils'
import locoScroll from '@/assets/js/LenisScroll'

export default function useSmoothScroll() {
  const { setScrollProgress } = useUtils()

  locoScroll.on('scroll', ({ progress }) => {
    setScrollProgress(progress)
  })

  const smoothScroll = locoScroll

  return {
    locoScroll,
    smoothScroll,
  }
}
